@layer components {
  .container {
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
    max-width: calc(100% - 48px);

    @media screen and (min-width: 1025px) {
      max-width: calc(100% - 144px);
    }
  }

  .button {
    @apply inline-flex select-none items-center justify-center rounded-md bg-color-tertiary px-3 text-[13px] leading-[36px] text-white hover:bg-color-secondary;

    &[disabled] {
      @apply pointer-events-none opacity-50;
    }

    &.is_empty {
      @apply bg-transparent text-color-tertiary ring-1 ring-color-tertiary hover:bg-color-tertiary hover:text-white;
    }
  }

  .center-y {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .center-x {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .pointer-events-all {
    pointer-events: all;
  }
}
